.theme-toggle-button {
  background: none;
  border: none;
  cursor: pointer;
  width: 32px;
  height: 32px;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.theme-toggle-icon {
  width: 24px;
  height: 24px;
  fill: var(--text-link);
}
