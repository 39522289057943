.gallery {
  display: flex;
  flex-direction: row;
  gap: 3rem;
  flex-wrap: nowrap;
  overflow-x: auto;
  margin-bottom: 8rem;
  touch-action: pan-x;
  scroll-behavior: smooth;
  padding: 0 calc(min(4rem, 5vw));
  padding-bottom: 1rem;
}

.gallery-image {
  width: 75vw;
  max-width: 560px;
  height: 100%;
  max-height: 75vh;
  box-shadow: var(--shadow);
}
