ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.about {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  padding: 0 calc(min(4rem, 5vw));
  margin-bottom: 6rem;
}

.about-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6rem;
}

.about-name {
  margin: 0;
}

.about-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
}

.about-heading {
  font-family: "Cooper BT", "Palatino Linotype", sans-serif;
  font-weight: 400;
  margin-bottom: 2rem;
  white-space: pre-wrap;
}

.about-links {
  display: flex;
  flex-wrap: wrap;
  column-gap: 1rem;
  row-gap: 0.25rem;
  margin-bottom: 4rem;
}

.about-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.about-albers {
  display: block;
  position: relative;
  width: 32px;
  height: 32px;
}

.albers-outer {
  background-color: #3a674a;
  position: absolute;
  width: 32px;
  height: 32px;
  bottom: 0;
  left: 0;
}

.albers-middle {
  background-color: #4b8865;
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 3px;
  left: 6px;
}

.albers-inner {
  background-color: #5eb764;
  position: absolute;
  width: 12px;
  height: 12px;
  bottom: 5px;
  left: 10px;
}
